<template>
  <div class="disabled_warning">
    <h3>App or feature not enabled</h3>
    <p class="functional">The app or feature you selected is not currently enabled for your user account.<br/><br/>
    If you have purchased it, or if you would like a 30 day trial, please select the green button below to open a support ticket and we’ll get right back to you.
    </p>
    <div class="btns">
      <btn class="button secondary" v-text="'got it'" @click="$emit('closingEvent')"></btn>
      <btn class="button" v-text="'open support ticket'" @click="$emit('closingEvent');goToHelp()"></btn>
    </div>
  </div>
</template>

<script>

import btn from '../buttons/button.vue';

export default {
  name: 'disabled',
  components: {
    btn,
  },
  data() {
  },
  methods: {
    goToHelp() {
      this.$router.push('/help');
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled_warning{
  width: calc(100% - 32px);
  max-width: 540px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px 32px 145px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  h3{
    margin: 0 0 32px 0;
  }
  p{
    margin: 0 auto;
    color: #787878
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0px;
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      padding: 0;
    }
    :first-child{
      width: 125px;
      margin-right: 16px;
    }
    :last-child{
      flex: 1;
    }
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape) {
  .disabled_warning{
    padding: 16px 16px 115px;
  }
}
</style>